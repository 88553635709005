import React from 'react';
import Layout from '../components/Layout/layout';
import Privacy from '../components/Privacy/Privacy';

const PrivacyPage: React.FC = () => {
  return (
    <Layout>
      <Privacy />
    </Layout>
  );
};

export default PrivacyPage;
